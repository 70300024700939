import React from 'react';

import { Helmet } from 'react-helmet';
import useInteractiveIframe from '../hooks/useInteractiveIframe';

const CustomIframeStyle = ({
  location,
  itemsStates,
}) => {
  const {
    parentScroll,
    parentHeight,
    maskedHeight,
  } = useInteractiveIframe({ key: itemsStates, location });

  return (
    <Helmet>
      <style>{`
        .sidebar-spacer { height: ${(parentScroll || 0) - 50}px; }
        .sidebar-content { height: ${parentHeight - (maskedHeight)}px; }

        .speedDialWithTooltip { top: ${(parentScroll || 0) + 60}px; }

        .MuiModal-root.MuiDialog-root { top: ${parentScroll || 0}px; }
        .MuiModal-root.MuiDialog-root .MuiDialog-container {
          height: ${parentHeight - (maskedHeight)}px;
        }
      `}
      </style>
    </Helmet>
  );
};

export default React.memo(CustomIframeStyle);
